<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-12">
              <form class="form-sample">
                <div class="col-md-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">New Ward</h4>
                      <div class="row">

                        <div class="col-md-3">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Type</label>
                              <multiselect v-model="$v.typeform.type.$model" :options="type" :multiple="false"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                placeholder="Type" :preselect-first="true" :showLabels="false"></multiselect>
                              <div v-if="$v.typeform.type.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.type.required">Please select Type</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Name</label>
                              <input type="text" class="form-control" v-model="$v.typeform.ward_name.$model"
                                :class="{ 'is-invalid': $v.typeform.ward_name.$error }" name="ward_name"
                                placeholder="Ward Name" />
                              <div v-if="$v.typeform.ward_name.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.ward_name.required">Please enter ward name</span>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Capacity</label>
                              <input type="number" class="form-control" v-model="$v.typeform.capacity.$model"
                                :class="{ 'is-invalid': $v.typeform.capacity.$error }" name="capacity"
                                placeholder="Ward Capacity" />
                              <div v-if="$v.typeform.capacity.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.capacity.required">Please enter ward capacity</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Description</label>
                              <textarea rows="5" type="text" class="form-control"
                                v-model="$v.typeform.description.$model" name="description" placeholder="Description"
                                :class="{ 'is-invalid': $v.typeform.description.$error }" />
                              <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.description.required">Please enter Description</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4" v-if="!this.$route.params.id">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">No Of ward</label>
                              <input type="number" class="form-control" v-model="$v.typeform.no_of_ward_to_add.$model"
                                :class="{ 'is-invalid': $v.typeform.no_of_ward_to_add.$error }" name="no_of_ward_to_add"
                                placeholder="No Of Ward to Add" />
                              <h6><small class="text-muted">How many wards you want to create at a time?</small></h6>
                              <div v-if="$v.typeform.no_of_ward_to_add.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.no_of_ward_to_add.required">Please enter no of Ward</span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-4">
                        <b-button v-if="is_btn_spinner" variant="primary" disabled>
                          <b-spinner small type="grow"></b-spinner>
                          Loading...
                        </b-button>
                        <button v-else type="button"
                          @click="($route.params.id && $route.params.id != '' ? submitForm('update') : submitForm('add'))"
                          class="btn btn-gradient-primary me-2">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import VoerroTagsInput from '@voerro/vue-tagsinput';
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"

export default {
  name: 'AddWard',
  title: string.PAGE_TITLE_ADD_MEDICINE,
  components: {
    NavBar,
    SideBar,
    VoerroTagsInput,
    Multiselect,
  },
  data() {
    return {
      wardlists: [],
      status: ['Active', 'Inactive'],
      type: ['General', 'Medical', 'Surgical', 'Pediatric'],
      typeform: {
        ward_name: '',
        type: '',
        capacity: '',
        description: '',
        no_of_ward_to_add: ''
      },
      is_btn_spinner: false,

    }
  },
  validations: {
    typeform: {
      ward_name: { required },
      type: {},
      no_of_ward_to_add: {numeric },
      capacity: { required, numeric },
      description: { required }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getWardData(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("hospital", ["addWardData", "getWardDetailsData", "editWardDetailsData"]),
    wardlisting(page) {
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.activePage = page;
        var bodyFormData = new FormData();

        this.getWardListData(bodyFormData).then((response) => {
          this.is_API_call_running = false;
          if (response.response_code == 200) {
            this.wardlists = response.data;
            this.listPages = response.no_of_pages;
          } else {
            this.errorMessage = response.message;
          }
        });
      }, 500)
    },
    submitForm(val) {
      this.is_btn_spinner = true;
      this.$v.$touch();
      if (!this.$v.typeform.$invalid) {
        var bodyFormData = new FormData();
        bodyFormData.append('name', this.typeform.ward_name);
        bodyFormData.append('type', this.typeform.type);
        bodyFormData.append('capacity', this.typeform.capacity);
        bodyFormData.append('description', this.typeform.description);
        if(this.typeform.no_of_ward_to_add) {
            bodyFormData.append('no_of_wards_to_add', this.typeform.no_of_ward_to_add);
        }
        if (val == 'add') {
          this.addWardData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.is_btn_spinner = false;
              this.$toasted.success("Ward added successfully", { duration: 2000, });
              this.$router.push({ name: 'rooms-wards' });
            } else {
              this.is_btn_spinner = false;
              this.$toasted.error(response.message, { duration: 2000, });
            }
          });
        } else if (val == 'update') {
          bodyFormData.append('ward_id', this.$route.params.id);

          this.editWardDetailsData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.is_btn_spinner = false;
              this.$toasted.success("Ward updated successfully", { duration: 2000, });
              this.$router.push({ name: 'rooms-wards' });
            } else {
              this.is_btn_spinner = false;
              this.$toasted.error(response.message, { duration: 2000, });
            }
          });
        }
      } else {
        this.is_btn_spinner = false;
      }
    },
    getWardData(id) {
      var bodyFormData = new FormData();
      bodyFormData.append('ward_id', id);
      this.getWardDetailsData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.typeform.ward_name = response.data.name;
          this.typeform.type = response.data.type;
          this.typeform.capacity = response.data.capacity;
          this.typeform.description = response.data.description;
          this.typeform.no_of_ward_to_add = response.data.no_of_ward_to_add;
        }
      })


    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
